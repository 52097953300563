<template>
  <div>
    <van-nav-bar
      title="修改密码"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form>
      <van-field
        v-model.trim="password"
        type="password"
        name="旧密码"
        label="旧密码"
        placeholder="旧密码"
      />
      <van-field
        v-model.trim="new_password"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="新密码"
      />
      <van-field
        v-model.trim="new_password2"
        type="password"
        name="重复新密码"
        label="重复新密码"
        placeholder="重复新密码"
      />
      <div style="margin: 16px">
        <van-button round block type="info" @click="doSave">保存</van-button>
      </div>
    </van-form>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";

export default {
  data() {
    return {
      password: "",
      new_password: "",
      new_password2: "",

    };
  },
  components: {
    CopyRight,
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },
    doSave() {
      if (!this.password) {
        this.$toast.fail("请填写旧密码");
        return;
      }

      if (this.password.length < 6) {
        this.$toast.fail("密码必须大于等于6位");
        return;
      }

      if (!this.new_password) {
        this.$toast.fail("请填写新密码");
        return;
      }

      if (this.new_password.length < 6) {
        this.$toast.fail("密码必须大于等于6位");
        return;
      }

      if (!this.new_password2) {
        this.$toast.fail("请填写重复新密码");
        return;
      }

      if (this.new_password != this.new_password2) {
        this.$toast.fail("两次输入密码不一致");
        return;
      }

      if (this.new_password == this.password) {
        this.$toast.fail("新密码不能和旧密码一样");
        return;
      }

      let postData = this.$qs.stringify({
            password:this.password,
            new_password:this.new_password,
            new_password2:this.new_password2,
            token:window.sessionStorage.getItem("token"),
        });

      axios
        .post("api/member/edit_password", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.$toast("密码修改成功");
            this.$router.push('/')
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log("login err-->" + err);
          this.$toast.fail("密码修改失败");
        });
    },
  },
};
</script>
